import shajs from "sha.js";
import * as cookie from "cookie";

import ipRegex from "ip-regex";

import { UserEntity } from "../entities/UserEntity";

export const userCookieKey = "ruid";

export const getInfo = (headers: Headers) => {
  const getIp = (headers: Headers): string => {
    // x-client-ip
    const xClientIp = headers.get("x-client-ip");
    if (xClientIp && isIp(xClientIp)) return xClientIp;

    // cf-connecting-ip
    const cfConnectingIp = headers.get("cf-connecting-ip");
    if (cfConnectingIp && isIp(cfConnectingIp)) return cfConnectingIp;

    // fastly-client-ip
    const fastlyClientIp = headers.get("fastly-client-ip");
    if (fastlyClientIp && isIp(fastlyClientIp)) return fastlyClientIp;

    // true-client-ip
    const trueClientIp = headers.get("true-client-ip");
    if (trueClientIp && isIp(trueClientIp)) return trueClientIp;

    // x-real-ip
    const xRealIp = headers.get("x-real-ip");
    if (xRealIp && isIp(xRealIp)) return xRealIp;

    // x-clust-client-ip
    const xClusterClientIP = headers.get("x-clust-client-ip");
    if (xClusterClientIP && isIp(xClusterClientIP)) return xClusterClientIP;

    // x-forwarded
    const xForwarded = headers.get("x-forwarded");
    if (xForwarded && isIp(xForwarded)) return xForwarded;

    // x-forwarded-for
    const xForwardedFor = headers.get("x-forwarded-for");
    if (xForwardedFor && isIp(xForwardedFor)) return xForwardedFor;

    // forwarded-for
    const forwardedFor = headers.get("forwarded-for");
    if (forwardedFor && isIp(forwardedFor)) return forwardedFor;

    // x-appengine-user-ip
    const xAppengineUserIp = headers.get("x-appengine-user-ip");
    if (xAppengineUserIp && isIp(xAppengineUserIp)) return xAppengineUserIp;

    // Cf-Pseudo-IPv4
    const cfPseudoIpv4 = headers.get("Cf-Pseudo-IPv4");
    if (cfPseudoIpv4 && isIp(cfPseudoIpv4)) return cfPseudoIpv4;

    // Host
    const host = headers.get("host");
    if (host && host.includes("localhost")) return "127.0.0.1";

    return "";
  };

  const isIp = (ipAddress: string): boolean => {
    if (isLocalhost(ipAddress)) return true;

    return (
      ipRegex.v4({ exact: true }).test(ipAddress) ||
      ipRegex.v6({ exact: true }).test(ipAddress)
    );
  };

  const isLocalhost = (ipAddress: string): boolean => {
    return ipAddress === "::1" || ipAddress === "127.0.0.1";
  };

  // Get IP adress from user.
  const ipAddress = getIp(headers);

  const data = {
    userAgent: headers.get("User-Agent") as string,
    ipAddress: isLocalhost(ipAddress)
      ? "localhost"
      : ipAddress.replace(/[.:]+/g, "").slice(0, -3),
  };

  return data;
};

/**
 * Function that will create a new - or get an existing - user which includes an hash for tracking.
 *
 * @returns UserEntity object
 */
export const getUserEntity = (headers: Headers): UserEntity => {
  // Get current cookie.
  const cookieHeader = cookie.parse(headers.get("cookie") || "");
  const userCookie = cookieHeader?.[userCookieKey] || null;

  if (!userCookie) {
    // Fetch user agent and IP address from user and catch any error.
    const infoData = getInfo(headers);

    // Store that data into variables
    const userAgent = infoData.userAgent;
    const ipAddress = infoData.ipAddress;

    // if (!userAgent) throw new Error("No user agent has been found.");
    // if (!ipAddress) throw new Error("No IP address has been found.");

    // Hash IP address and user agent with bcrypt.
    const hashedIpAddressAndUserAgent = shajs("sha256")
      .update(ipAddress.concat(userAgent))
      .digest("hex");

    // Create an user entity.
    const userEntity = UserEntity.build({ id: hashedIpAddressAndUserAgent });

    return userEntity;
  } else {
    return UserEntity.build({ id: userCookie });
  }
};
