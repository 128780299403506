import {
  FeatureApiResponse,
  GrowthBook,
  TrackingCallback,
} from "@growthbook/growthbook";
import { userCookieKey } from "@reshift/tracking";
import { UAParser } from "ua-parser-js";
import { getEnv } from "api/utils";
import cookie from "cookie";
import cookies from "js-cookie";
import { setGb } from "stores/growthbook";
import { gtmEvent } from "./gtm";

export const onExperimentView: TrackingCallback = (experiment, result) => {
  gtmEvent("experiment_viewed", {
    experiment_id: experiment.key,
    variation_id: result.key,
  });
};

export const getAttributesFromUrl = (link: string) => {
  const url = new URL(link);

  return {
    url: url.href,
    host: url.host,
    path: url.pathname,
    query: url.search,
    utmSource:
      url.searchParams.get("utm_source") || cookies.get("utm_source") || null,
    utmMedium:
      url.searchParams.get("utm_medium") || cookies.get("utm_medium") || null,
    utmCampaign:
      url.searchParams.get("utm_campaign") ||
      cookies.get("utm_campaign") ||
      null,
    utmTerm:
      url.searchParams.get("utm_term") || cookies.get("utm_term") || null,
    utmContent:
      url.searchParams.get("utm_content") || cookies.get("utm_content") || null,
  };
};
export const getAttributesFromUserAgent = (userAgent: string) => {
  const { browser, device } = UAParser(userAgent);

  return {
    deviceType: device.is("mobile") ? "mobile" : "desktop",
    browser: browser.name?.toLowerCase() || null,
  };
};

export async function getGrowthBookServerContext(headers: Headers) {
  const cookieHeader = cookie.parse(headers.get("cookie") || "");

  const userCookie = cookieHeader?.[userCookieKey] || null;
  const uid = cookieHeader?.["rsft_uid"] || null;
  const sid = cookieHeader?.["rsft_sid"] || null;

  let attributes: Record<string, string | null> = {
    rsid: userCookie,
    uid,
    sid,
  };

  const referer = headers.get("referer");
  const userAgent = headers.get("user-agent");

  if (referer) attributes = { ...attributes, ...getAttributesFromUrl(referer) };
  if (userAgent)
    attributes = { ...attributes, ...getAttributesFromUserAgent(userAgent) };

  const gb = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: getEnv("GROWTHBOOK_API_TOKEN"),
    debug: true,
    attributes,
  });

  await gb.init({ timeout: 1000 });

  const payload = gb.getPayload();

  if (payload.features)
    Object.keys(payload.features).forEach((key) => gb.evalFeature(key));

  const trackingData = gb.getDeferredTrackingCalls();

  gb.destroy();

  return { client: gb, payload, trackingData };
}

export async function initGrowthBookClient() {
  const userCookie = cookies.get(userCookieKey) || null;
  const uid = cookies.get("rsft_uid") || null;
  const sid = cookies.get("rsft_sid") || null;

  let attributes: Record<string, string | null> = {
    rsid: userCookie,
    uid,
    sid,
  };

  const referer = window.location.href;
  const userAgent = window.navigator.userAgent;

  if (referer) attributes = { ...attributes, ...getAttributesFromUrl(referer) };
  if (userAgent)
    attributes = { ...attributes, ...getAttributesFromUserAgent(userAgent) };

  const gb = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: "sdk-1KMLfISMp9z8DjH",
    attributes,
    enableDevMode: true,
    trackingCallback: onExperimentView,
  });

  await gb.init({ timeout: 1000 });

  gb.setRenderer(() => {
    setGb(gb);
  });

  return gb;
}

export function initializeGrowthBookClient(
  payload: FeatureApiResponse
): GrowthBook {
  const userCookie = cookies.get(userCookieKey);

  let attributes: Record<string, string | null> = {
    rsid: userCookie || null,
  };

  const referer = window.location.href;
  const userAgent = window.navigator.userAgent;

  if (referer) attributes = { ...attributes, ...getAttributesFromUrl(referer) };
  if (userAgent)
    attributes = { ...attributes, ...getAttributesFromUserAgent(userAgent) };

  const gb = new GrowthBook({
    attributes,
    enableDevMode: true,
    trackingCallback: onExperimentView,
  }).initSync({ payload });

  gb.setRenderer(() => {
    setGb(gb);
  });

  return gb;
}
